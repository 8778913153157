// Test custom domains by setting a ENV var instead of having to point localhost
// at a DNS override. ie we no longer have to do this in hosts files:
//   127.0.0.1 brand.homedepot.com
// To use set a "custom domain" locally just fire up app-ui via:
//   NEXT_PUBLIC_KS_CUSTOM_DOMAIN_SITE_ID=knapsack-nucleus npm start
const customDomainOverride = process.env.NEXT_PUBLIC_KS_CUSTOM_DOMAIN_SITE_ID;

/**
 * Key of custom domain, value of siteId
 * Custom Domains set up here: https://vercel.com/knapsack-cloud/knapsack-cloud-app/settings/domains
 */
const customDomainMap = new Map([
  ['ks-test-design-system.vercel.app', 'ks-test'],
  // College Board domains
  ['designsystem.collegeboard.org', 'collegeboard'],
  // Home Depot domains
  ['brand.homedepot.com', 'knapsack-nucleus'],
  // Edward Jones domains
  ['designsystem.edwardjones.com', 'axiom'],
  // Sherwin Williams domains
  ['primary.sherwin.com', 'primary'],
  // Bill
  ['content.bill.design', 'bill'],
  // Teradata
  ['design.teradata.com', 'covalent'],
  // GSK
  ['omnidesign.gsk.com', 'gsk-digital-channels'],
  // KS Docs
  ['docs.knapsack.cloud', 'ks-docs'],
  // uncomment below to test locally
  // ['localhost', 'ks-test'],
]);

if (customDomainOverride) {
  console.debug(`Using KS_CUSTOM_DOMAIN_SITE_ID: ${customDomainOverride}`);
  // Ensure the only custom domains we react to are those explicitly set above
  if (![...customDomainMap.values()].includes(customDomainOverride)) {
    throw new Error(
      `The env var "KS_CUSTOM_DOMAIN_SITE_ID" does not have an entry in 'customDomainMap`,
    );
  }
}

export const hasCustomDomainOverride = !!customDomainOverride;

export const getCustomDomainId = (hostname: string) =>
  customDomainOverride || customDomainMap.get(hostname);
