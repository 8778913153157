export const customLandingPageSites = [
  'axiom',
  'gsk-us',
  'gsk-us-usxf3',
  'gsk-us-staging',
  'knapsack-nucleus',
  'gsk-digital-channels',
  'opisnet',
  'nationalgrideso',
  'nationalenergyso',
] as const;

export type SiteWithCustomLandingPage = (typeof customLandingPageSites)[number];

export type CustomLandingPageProps = {
  siteId: SiteWithCustomLandingPage;
};
