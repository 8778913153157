import { hasuraGql } from '@/services/hasura-client';

export async function matchCustomToSpecificSiteInstance(
  customUrl: string,
): Promise<
  | {
      hasMatch: true;
      siteId: string;
      instanceId: string;
      isLatestInstance: boolean;
    }
  | { hasMatch: false }
> {
  // We want to search for deploys that match either `x.com` OR `x.com/` - First: remove any trailing `/`
  const url = customUrl.endsWith('/') ? customUrl.slice(0, -1) : customUrl;

  const data = await hasuraGql.GetMatchingDeploys({
    urls: [url, `${url}/`],
  });
  // What came in as `/site/custom/https://123.com` is now matched to siteInstance so should be redirected to `/site/siteId/instanceid`
  const [deploy] = data?.siteInstances ?? [];
  if (!deploy) {
    return {
      hasMatch: false,
    };
  }
  const {
    site: { id: siteId, latestUrl },
    instanceId,
  } = deploy;
  if (!siteId || !instanceId) {
    return {
      hasMatch: false,
    };
  }
  return {
    hasMatch: true,
    siteId,
    instanceId,
    isLatestInstance: latestUrl === url || latestUrl === `${url}/`,
  };
}
